<template>
  <div :class="className" :style="{height:height,width:width}"/>
</template>

<script>
import echarts from 'echarts' // echarts theme
import { debounce } from '@/utils'
require('echarts/theme/macarons')

export default {
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '350px'
    },
    autoResize: {
      type: Boolean,
      default: true
    },
    chartData: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      chart: null,
      sidebarElm: null
    }
  },
  watch: {
    chartData: {
      deep: true,
      handler(val) {
        this.setOptions(val)
      }
    }
  },
  mounted() {
    this.initChart()
    if (this.autoResize) {
      this.__resizeHandler = debounce(() => {
        if (this.chart) {
          this.chart.resize()
        }
      }, 100)
      window.addEventListener('resize', this.__resizeHandler)
    }

    // 监听侧边栏的变化
    this.sidebarElm = document.getElementsByClassName('sidebar-container')[0]
    this.sidebarElm &&
      this.sidebarElm.addEventListener(
        'transitionend',
        this.sidebarResizeHandler
      )
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    if (this.autoResize) {
      window.removeEventListener('resize', this.__resizeHandler)
    }

    this.sidebarElm &&
      this.sidebarElm.removeEventListener(
        'transitionend',
        this.sidebarResizeHandler
      )

    this.chart.dispose()
    this.chart = null
  },
  methods: {
    sidebarResizeHandler(e) {
      if (e.propertyName === 'width') {
        this.__resizeHandler()
      }
    },
    setOptions(val) {
      this.chart.setOption({
        xAxis: {
          data: val.newVisitis.actualData,
          boundaryGap: false,
          axisTick: {
            show: false
          }
        },
        grid: {
          left: '5%',
          right: '5%',
          bottom: '6%',
          top: '10%',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          },
          padding: [10, 15]
        },
        yAxis: {
          axisTick: {
            show: false
          }
        },
        /** 点击隐藏显示**/
        // legend: {
        //   x: "center",
        //   y: "bottom",
        //   data: ["expected", "actual"]
        // },
        toolbox: {
          show: true,
          feature: {
            dataView: { readOnly: false },
            magicType: { type: ['line', 'bar'] },
            restore: {},
            saveAsImage: {}
          }
        },
        // 配置数据和鼠标移入显示
        series: [
          {
            name: '新增用户数',
            itemStyle: {
              normal: {
                color: '#FF005A',
                lineStyle: {
                  color: '#FF005A',
                  width: 2
                }
              }
            },
            smooth: 0,
            type: 'line',
            data: val.newVisitis.expectedData,
            animationDuration: 2800,
            animationEasing: 'cubicInOut'
          }
          // {
          //   name: "actual",
          //   smooth: 0,
          //   type: "line",
          //   itemStyle: {
          //     normal: {
          //       color: "#3888fa",
          //       lineStyle: {
          //         color: "#3888fa",
          //         width: 2
          //       },
          //       areaStyle: {
          //         color: "#f3f8ff"
          //       }
          //     }
          //   },
          //   data: val.newVisitis.actualData,
          //   animationDuration: 2800,
          //   animationEasing: "quadraticOut"
          // }
        ]
      })
    },
    initChart() {
      this.chart = echarts.init(this.$el, 'macarons')
      this.setOptions(this.chartData)
    }
  }
}
</script>
