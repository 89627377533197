<template>
  <div class="dashboard-editor-container">
    <panel-group @handleSetLineChartData="handleSetLineChartData"/>

    <el-row style="background:#fff;padding:16px 25px 16px;margin-bottom:32px;">
      <div class="chat-title">
        <svg-icon icon-class="tree" class-name="chat-icon"/>新建账号数量
      </div>
      <line-chart :chart-data="lineChartData"/>
    </el-row>

    <!-- <el-row :gutter="32">
      <el-col :xs="24" :sm="24" :lg="8">
        <div class="chart-wrapper">
          <div class="chat-title">
            <svg-icon icon-class="tree" class-name="chat-icon"/>新建岗位数量
          </div>
          <raddar-chart/>
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :lg="8">
        <div class="chart-wrapper">
          <div class="chat-title">
            <svg-icon icon-class="tree" class-name="chat-icon"/>活跃账号数量
          </div>
          <pie-chart/>
        </div>
      </el-col>
      <el-col :xs="24" :sm="24" :lg="8">
        <div class="chart-wrapper">
          <div class="chat-title">
            <svg-icon icon-class="tree" class-name="chat-icon"/>潜水账号数量
          </div>
          <bar-chart/>
        </div>
      </el-col>
    </el-row>-->
  </div>
</template>

<script>
import PanelGroup from './components/PanelGroup'
import LineChart from './components/LineChart'
// import RaddarChart from "./components/RaddarChart";
// import PieChart from "./components/PieChart";
// import BarChart from "./components/BarChart";
import { getData } from '../../../api/homePage.js'

// const lineChartData = {
//   newVisitis: {
//     expectedData: [],
//     actualData: []
//   },
//   messages: {
//     expectedData: [],
//     actualData: []
//   },
//   purchases: {
//     expectedData: [],
//     actualData: []
//   },
//   shoppings: {
//     expectedData: [],
//     actualData: []
//   }
// };

export default {
  name: 'DashboardAdmin',
  components: {
    PanelGroup,
    LineChart
    // RaddarChart,
    // PieChart,
    // BarChart
  },
  data() {
    return {
      lineChartData: {
        newVisitis: {
          expectedData: [],
          actualData: []
        },
        // messages: {
        //   expectedData: [],
        //   actualData: []
        // },
        // purchases: {
        //   expectedData: [],
        //   actualData: []
        // },
        // shoppings: {
        //   expectedData: [],
        //   actualData: []
        // },
        data: [],
        hideAccount: 0,
        active: 0,
        post: 0,
        account: 0
      }
    }
  },
  created() {
    this.getData()
  },
  methods: {
    // 获取数据
    getData() {
      getData()
        .then(response => {
          // console.log(response);
          this.lineChartData.newVisitis.expectedData =
            response.data.userNewAccountGroup.countList
          // console.log(this.lineChartData.newVisitis.expectedData);

          // this.lineChartData.newVisitis.actualData = [12, 5, 10, 6, 10, 7, 20];
          // console.log(this.lineChartData.newVisitis.actualData);

          this.lineChartData.newVisitis.actualData =
            response.data.userNewAccountGroup.dateList
          // console.log(this.lineChartData.newVisitis.actualData);

          // this.lineChartData.data = response.data.userNewAccountGroup.dateList;
          // console.log(this.lineChartData.data);

          this.active = response.data.activeAccountNumber
          this.hideAccount = response.data.divingAccountNumber
          this.account = response.data.newAccountNumber
          this.post = response.data.newPositionNumber
        })
        .catch(error => {
          console.log(error)
        })
    },
    handleSetLineChartData(type) {
      console.log('类型', type)

      // console.log("类型2", this.lineChartData);
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.dashboard-editor-container {
  padding: 32px;
  background-color: rgb(240, 242, 245);
  .chart-wrapper {
    background: #fff;
    padding: 16px 16px 0;
    margin-bottom: 32px;
  }
}
.chat-title {
  line-height: 49px;
  padding-bottom: 0;
  color: #36a3f7;
  font-size: 14px;
  font-weight: 600;
  height: auto;
  overflow: hidden;
  padding: 0 4px;
  margin-bottom: 6px;
  border-bottom: 1px solid #ebeef5;
  .chat-icon {
    margin-right: 4px;
  }
}
</style>
